
<ng-container [ngSwitch]="IconType">
    <svg
    class="feather"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    *ngSwitchCase="'fi'"
    >
        <use attr.xlink:href="assets/fonts/feather/feather-sprite.svg#{{IconName}}"/>
    </svg>
    <ng-container *ngSwitchCase="'letter'">
        {{IconName}}
    </ng-container>
    <i *ngSwitchDefault class="{{IconType}}-icon">{{IconName}}</i>
</ng-container>
